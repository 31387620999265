/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-botton: 2em;
}

/*END RESET*/

html {
  font-size: 100%;
  font-family: Arial, Helvetica, sans-serif;
  height: 100vh;
  --blue: #2699fb;
  --mid-blue: #7fc4fd;
  --light-blue: #bce0fd;
  --lightest-blue: #f1f9ff;
  --red: red;
  --green: #5FB55F;
  --dark-white: #DBDBDB;
  --background: #2F2E2E;
  --dark-background: #111;
}

body {
  background-color: var(--background);
  color: var(--dark-white);
}

table {
  table-layout: fixed;
  width: 100%;
  padding-bottom: 1em;
}

td {
  width: 25%;
}

input {
  background-color: var(--background);
  color: var(--dark-white);
  border: 0;
  caret-color: white;
  display: block;
  padding: .5em 0 .5em 0;
  text-align: center;
  position: relative;
  margin: auto;
  min-width: 3em;
  max-width: 70%;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  box-sizing:content-box;
  -webkit-box-sizing:content-box;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

button {
  background-color: transparent;
  color: var(--dark-white);
  max-width: 60%;
  height: 20px;
  border: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0;
  font-size: 100%;
}

button:hover {
  background-color: var(--dark-background);
  cursor: pointer;
}

button:focus{
  outline: 0;
}

header{
  margin-bottom: 2em;
}

.content {
  text-align: left;
  align-items: center;
  margin: auto;
  width: 80%;
  overflow: auto;
}

.App{
  text-align: center;
  margin: auto;
}
