p{
    display: table-row;
}

label {
    display: table-cell;
}

form {
    display: table;
}

input {
    display: table-cell;
}

.heading{
    overflow: auto;
    margin-bottom: 1em;
}

.project {
    width: 80%;
    display: flex;
    justify-content: space-between;
    max-height: 70vh;
    overflow-y: scroll;
    margin: auto;
}

@media(min-width: 850px) {
    .heading {
        width: 80%;
        margin: auto;
    }
}

.basketball-player-info{
    display: table-cell;
    margin-right: 1rem;
    padding-right: 1rem;
    height: 20px;
    width: 20px;
}

.bb-results-span{
    margin-top: .5em;
    display: block;
}

.description{
    display: none;
}

#results{
    overflow-y: scroll;
    max-height: 512px;
}

.basketball-player-div {
    display: table-row;
}

@media (max-width:850px) {
    .project {
        display: block;
        float: none;
        width: 100%;
        max-height: 70vh;
        overflow-y: scroll;
    }
}
